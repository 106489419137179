import React from "react"
import Layout from "../components/layout/Layout"
import SEO from "../components/SEO"
import { graphql } from 'gatsby';
import { mapPageData } from '../utils/Mappers';
import useModules from '../utils/useModules';
import Img from 'gatsby-image'
import PageNav from '../components/layout/PageNav';

const OurProductPillars = ({ data = {} }) => {

  const { modules, title, description = false, id } = mapPageData(data.page)
  const { backgroundImage } = data
  const [modulesToRender, heroModule] = useModules(modules, { getHeroModule: true })

  return (
    <>
      <SEO title={title} description={description} />
      <Layout
        staticMenuLayout={true}
        homeLink={true}
        staticMenu={true}
        heroModule={heroModule}
        className="static-page relative overflow-x-hidden our-product-pillars ">
        {/* Content goes here */}


        {backgroundImage && <div style={{ zIndex: -1 }}className="absolute top-0 w-full "><Img fluid={backgroundImage.fluid}></Img>   </div>}

        <div className="lg:py-12"></div>
        {modulesToRender.map(({ Module, id }) => <Module key={id} />)}

        <PageNav 
        prev={{  text: "Packaging in Practice", linkTo: "/packaginginpractise"}} 
        next={{  text: "Download Assets", linkTo: "/downloadassets"}}
      />
      
      </Layout>
    </>
  )
}

export default OurProductPillars


export const OurProductPillarsQuery = graphql`
  query OurProductPillarsQuery {
  page: contentfulPage(pageId: {eq: "our-product-pillars"}) {
      ...PageFragment
    }

    backgroundImage: contentfulAsset(contentful_id: {eq: "49XpZsfOVxgzDhAkDobh3Y"}) {
      ...GatsbyFluidImageFragment
    }
  }
`
